import React from 'react';
import { Field } from 'formik';
import { masks, retainCursorPositionOnChange } from '../../helperFunctions';
import Label from './Label';
import { getSetValueFunction } from '../../services/formik_form/helperFunctions';

const DateInputField = ({
  input,
  label,
  type = 'text',
  placeholder = 'MM/DD/YYYY',
  disabled,
  className,
  containerClass,
  hideError,
  onKeyDown,
  labelClass,
  showRequiredLabel,
  meta: { touched, error, warning } = {},
  setValue,
}) => (
  <React.Fragment>
    <Label {...{ input, label, labelClass, showRequiredLabel }} />
    <span className={`${containerClass || ''}`}>
      <span style={{ position: 'relative', display: 'block' }}>
        <input
          {...input}
          type={type}
          disabled={disabled}
          className={`ip-text ip-text-block ${className || ''} ${
            touched && error ? 'ip-has-error' : ''
          }`}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            background: 'transparent',
          }}
          onKeyDown={onKeyDown}
          onChange={(e) => {
            const maskCorrectedValue = masks.date(e.target.value, input.value);
            if (setValue) {
              setValue(maskCorrectedValue);
            } else {
              input.onChange(maskCorrectedValue);
            }
            retainCursorPositionOnChange(e, input);
          }}
          inputMode="numeric"
        />
        <input
          value=""
          readOnly
          placeholder={`${input.value}${placeholder.substring(
            input.value.length,
            placeholder.length,
          )}`}
          type={type}
          disabled={disabled}
          className={`ip-text ip-text-block ${className || ''}`}
        />
      </span>

      {!hideError &&
        touched &&
        ((error && <span className="error">{error}</span>) ||
          (warning && <span>{warning}</span>))}
    </span>
  </React.Fragment>
);

const DateInputFieldFormik = ({ name, disabled, hideError = true }) => (
  <Field name={name}>
    {(props) => {
      const { field, form } = props;
      const { onChange, ...input } = field; // field has name, value, onChange, onBlur
      const { setFieldValue } = form;
      const setValue = getSetValueFunction(name, setFieldValue);
      return <DateInputField {...{ input, hideError, setValue, disabled }} />;
    }}
  </Field>
);

const maxDateCurrentDate = new Date();
const minDateDefault = new Date('1/1/1900');

export { DateInputFieldFormik, maxDateCurrentDate, minDateDefault };
export default DateInputField;
