import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import './style.scss';
import MessagePage from './message';
import ErrorPage from './error';
import ProtectedSiteLogin from './error/ProtectedSiteLogin';
import PermissionCheck from './error/PermissionCheck';
import Forms from './forms';
import ShowSecuredMessage from './ShowSecuredMessage';

const components = [
  { name: MessagePage, path: '/message' },
  { name: ErrorPage, path: '/error' },
  { name: ErrorPage, path: '/show_messages' },
  { name: ErrorPage, path: '/error_appointment' },
  { name: ProtectedSiteLogin, path: '/protected_site_login' },
  { name: PermissionCheck, path: '/permission_check' },
  { name: Forms, path: '/secured_message/:id' },
  { name: ShowSecuredMessage, path: '/appointment_email' },
];

class LoginApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      idleTimeLimit: props.timeOut ? props.timeOut : 120000,
    };
    this.activityTimeRef = React.createRef(Date.now());
  }

  resetTimer = () => {
    this.activityTimeRef.current = Date.now();
  };

  leavePage = () => {
    const { idleURL } = this.props;
    const { idleTimeLimit } = this.state;
    const idleSince = Date.now() - this.activityTimeRef.current; // get difference in milliseconds;
    if (idleSince > idleTimeLimit) {
      clearInterval(this.interval);
      if (idleURL) {
        window.location.href = idleURL;
      }
    }
  };

  _onMouseMove = (e) => {
    this.resetTimer();
  };

  _onTouchStart = (e) => {
    this.resetTimer();
  };

  _onKeyPress = (e) => {
    this.resetTimer();
  };

  setLoader = (isShown = true) => {
    this.setState({
      loading: isShown,
    });
  };

  setProgress = (progress) => {
    this.setState({
      progress,
    });
  };

  setRoutes = () => {
    const routeList = [];
    components.forEach((item, index) => {
      const DynamicComponent = item.name;
      routeList.push(
        <Route
          exact
          key={index}
          path={item.path}
          render={(props) => (
            <DynamicComponent
              {...props}
              {...this.props}
              setLoader={this.setLoader}
              setProgress={this.setProgress}
            />
          )}
        />,
      );
    });
    return routeList;
  };

  componentDidMount() {
    const { logoutIfIdle = true } = this.props;
    this.resetTimer(); // by default the ref is assigned to DOM element so we want to update it to current time
    if (logoutIfIdle) {
      this.interval = setInterval(() => this.leavePage(), 10000);
    } else {
      clearInterval(this.interval);
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const { pageName, randomizeClass } = this.props;
    const { loading, progress } = this.state;
    return (
      <div
        ref={this.activityTimeRef}
        onMouseMove={this._onMouseMove.bind(this)}
        onTouchStart={this._onTouchStart.bind(this)}
        onKeyPress={this._onKeyPress.bind(this)}
        className={`${pageName} ${randomizeClass} login-pages react-module-pages`}
      >
        <Router>{this.setRoutes()}</Router>
        {loading && (
          <div className="loader">
            <div />
          </div>
        )}
      </div>
    );
  }
}

export default LoginApp;
