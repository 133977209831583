import React, { useCallback } from 'react';

import clsx from 'clsx';
import styles from './message_template_styles.module.scss';
import { confirmOrCancelAppointment } from '../apis';
import CancelReason from '../../appointments/CancelReason';
import { useModal } from '../../../context/modal';
import useLoader from '../../../context/utilities/useLoader';

const ConfirmAppointment = ({ isLive, metadata, children }) => {
  const {
    encounter_id: encounterId,
    organization_id: organizationID,
    encounter_accepted: encounterAccepted,
    is_state_changeable: isStateChangeable,
    patient_notification_confirmation_button: confirmationButton,
    is_recurring: isRecurring,
    mss_active: mssActive,
    mss_home_url: mssHomeUrl,
    status_change_reason_enabled: statusChangeReasonEnabled,
    repeat_details: repeatDetails,
    disable_buttons: disableButtons,
    translations,
  } = metadata || {};

  const { setModal, unSetModal } = useModal();
  const { setLoader } = useLoader();

  const handleConfirmOrCancelAppointment = (type) => {
    if (!isLive) {
      return;
    }
    confirmOrCancelAppointment(encounterId, type).then(() => {
      // to do
    });
  };

  const handleCancelAppointmentWithReason = useCallback(() => {
    if (!isLive) {
      return;
    }
    setModal(
      <CancelReason
        id={encounterId}
        closeModal={unSetModal}
        formName="cancel-reason"
        setLoader={setLoader}
        getEvents={() => {}}
        /* eslint-disable-next-line jsx-a11y/aria-role */
        role="member"
        organizationID={organizationID}
      />,
    ).then((status) => {
      if (status && typeof status === 'string' && status === 'success') {
        // to do
      }
    });
    // eslint-disable-next-line no-restricted-globals
  }, []);

  const MessageButtons = () =>
    isStateChangeable ? (
      <div className={clsx(styles.buttonWrapper, 'button-wrapper')}>
        {confirmationButton && (
          // eslint-disable-next-line jsx-a11y/control-has-associated-label,react/button-has-type
          <button
            type="button"
            className="btn btn-primary email_btn btn"
            disabled={disableButtons}
            onClick={() => handleConfirmOrCancelAppointment('confirm')}
          >
            {isRecurring
              ? translations?.click_to_confirm_recurring_appointment
              : translations?.click_to_confirm_appointment}
          </button>
        )}
        <button
          type="button"
          className="txt-red appointment_btn email_btn btn btn-default"
          disabled={disableButtons}
          onClick={() => {
            if (statusChangeReasonEnabled) {
              handleCancelAppointmentWithReason();
            } else {
              handleConfirmOrCancelAppointment('cancel');
            }
          }}
        >
          {isRecurring
            ? translations?.click_to_cancel_recurring_appointment
            : translations?.click_to_cancel_appointment}
        </button>
      </div>
    ) : null;

  const InfoText = () => (
    <div className={clsx('other-details', styles.mt1, styles.otherDetails)}>
      {encounterAccepted ? (
        <p>{translations.mss_is_accepted}</p>
      ) : disableButtons ? (
        <p>{translations.already_confirmed_cancelled}</p>
      ) : null}
      {mssActive && (
        <p>
          {translations.mss_access_appt_portal},{' '}
          <a href={mssHomeUrl} className={styles.portalLink}>
            {translations.please_click_here}
          </a>
          .
        </p>
      )}
      {isRecurring && (
        <div className="repeat-det">
          <b>{translations['appointment-repeats']}:</b>
          <p>
            <i>{repeatDetails}</i>
          </p>
        </div>
      )}
    </div>
  );

  return (
    <div className={clsx(styles.confirmAppointmentTemplate, 'w-100')}>
      {children}
      <MessageButtons />
      <InfoText />
    </div>
  );
};

export default ConfirmAppointment;
