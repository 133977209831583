/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import styles from './LanguageSelector.module.scss';

const defaultSelectionLabels = {
  en: 'in English',
  es: 'en Español',
};

const getHref = (tag) => `/select_language/${tag}`;

const defaultSupportedLanguages = [{ tag: 'en' }, { tag: 'es' }];

const LanguageSelector = ({
  selectorType = 'SingleSelectButton',
  supportedLanguages,
  onChange,
  wraperClasses = '',
  ...props
}) => {
  const languageTags = supportedLanguages.map((language) => language.tag);

  useEffect(() => {
    if (selectorType === 'SingleSelectButton' && languageTags.length > 2)
      console.warn(
        `${languageTags.length} languages are supported, but the selectorType is 'SingleSelectButton'`,
      );
  }, [languageTags.length, selectorType]);

  return selectorType === 'SingleSelectButton' ? (
    <SingleSelectButton
      {...{ languageTags, onChange, wraperClasses, ...props }}
    />
  ) : null;
};

const SingleSelectButton = ({
  currentLocale,
  languageTags,
  onChange,
  wraperClasses,
}) => {
  const otherLocale = languageTags.find((tag) => tag !== currentLocale);
  const label = defaultSelectionLabels[otherLocale];

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      href={onChange ? '#' : getHref(otherLocale)}
      className={`${styles.singleSelectButton} ${wraperClasses}`}
      onClick={() => {
        if (onChange) {
          onChange(otherLocale);
        }
      }}
    >
      <span>{label}</span>
    </a>
  );
};

export { defaultSupportedLanguages };
export default LanguageSelector;
