import clsx from 'clsx';
import React from 'react';
import styles from './LucetLogo.module.scss';

const SecureMessageLogo = ({
  logoDetails,
  wrapperClasses,
  showCaption = true,
}) => {
  const {
    image,
    class: imageClass,
    alt,
    caption,
  } = logoDetails?.white_logo || {};
  return (
    <div className={clsx(styles.lucetLogoWrapper, wrapperClasses)}>
      <img
        className={imageClass || 'lucet-default-login-logo'}
        alt={alt || 'Lucet logo'}
        src={`/assets/${image}`}
      />
      {showCaption && caption && <span>{caption}</span>}
    </div>
  );
};

export default SecureMessageLogo;
