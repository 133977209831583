import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import styles from './custom_messages.module.scss';
import { queryBuilder } from '../../helperFunctions';
import { fetchPreview } from './apis';
import ConfirmAppointment from './templates/ConfirmAppointment';
import ComponentLoader from '../UI/ComponentLoader';

const PreviewBody = ({
  messageInstanceId,
  filterData = {},
  locale = 'en',
  mode = 'view',
}) => {
  const [previewData, setPreviewData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const isLive = mode === 'live'; // mode options case be view, edit or live

  const { message, metadata } = previewData || {};

  const loadPreview = () => {
    if (filterData && filterData.organization_id) {
      const queryData = queryBuilder(filterData || {});
      setIsLoading(true);
      fetchPreview(messageInstanceId, queryData, locale).then((r) => {
        setIsLoading(false);
        if (r.data && r.data.message) {
          setPreviewData(r.data);
        }
      });
    }
  };

  useEffect(() => {
    loadPreview();
  }, [filterData, locale]);

  return (
    <ComponentLoader
      isLoading={isLoading}
      customClass="w-100"
      addMinHeight
      blurContentWhileLoading
    >
      <div
        className={clsx(
          isLive ? 'is-live' : 'is-preview',
          styles.previewBodyInner,
        )}
      >
        <div className={clsx(styles.previewContainer)}>
          <ConfirmAppointment isLive={isLive} metadata={metadata}>
            <div dangerouslySetInnerHTML={{ __html: message || '' }} />
          </ConfirmAppointment>
        </div>
      </div>
    </ComponentLoader>
  );
};

export default PreviewBody;
